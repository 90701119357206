import { View, Text, StyleSheet, ImageBackground, Button } from 'react-native';
import { useState, useCallback } from 'react';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import { useNavigate } from 'react-router-dom';
import { Pressable } from 'react-native-web';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [zipCode, setZipCode] = useState("");
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate();

    const onStartButtonPress = useCallback(() => {
        navigate({
            pathname: '/info'
        })
    }, [navigate, zipCode])

    return (
        <View style={styles.root}>
            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />

                <View style={styles.mainContent} >
                    <Text style={styles.description}>{messages.description}</Text>
                    <View style={styles.inputView}>
                        <Pressable style={[styles.buttonView]}
                            onPress={onStartButtonPress}>
                            <Text style={[styles.buttonContent]}>{messages.seeQuotes}</Text>
                        </Pressable>
                    </View>
                </View>
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        contentView: {
            height: window.innerHeight,
            width: "100%",
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        description: {
            color: "white",
            fontWeight: "700",
            fontFamily: "Electrolize-Regular",
        },
        inputView: {
            flexDirection: "row",
            marginTop: 40
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#3EA1FB",
            marginLeft: 20,
            position: "relative",
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
        },
        buttonContent: {
            color: "#FFFFFF",
            fontSize: 18,
            lineHeight: "100%",
            fontWeight: "700",
            padding: 14,
            fontFamily: "Electrolize-Regular",
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        title: {
            ...baseStyles.title,
            fontSize: 22,
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "center"
        },
        mainContent: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            marginBottom: "20%",
        },
        description: {
            ...baseStyles.description,
            width: 500,
            fontSize: 64,
            lineHeight: 66
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    description: "Let's find your next step in your career together!",
    zipCode: "ENTER YOUR ZIP CODE",
    seeQuotes: "START NOW",
}

export default AutoHomePage;
