import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Privacy Policy `}</Text>
            <Text style={styles.description}>{`Welcome to CareerBoostConsult.com (referred to herein as "we," "us," or "our"). Safeguarding your privacy is paramount to us. This Privacy Policy delineates how we gather, utilize, disclose, and protect your personal information. By accessing or utilizing our website(s) and app(s), you signify your consent to the collection and utilization of your information as outlined in this policy.`}</Text>
            <Text style={styles.title}>{`Information Collection:`}</Text>
            <Text style={styles.description}>{`We gather personal information you provide to us, such as your name, email address, and any other details you willingly disclose. Additionally, we may automatically collect information such as your IP address and browsing activities.`}</Text>
            <Text style={styles.title}>{`Utilization of Information:`}</Text>
            <Text style={styles.description}>{`We employ your information to furnish and personalize our services, communicate with you, and enhance our offerings. Your information may also be utilized for administrative, analytical, and research purposes.`}</Text>
            <Text style={styles.title}>{`Disclosure of Information:`}</Text>
            <Text style={styles.description}>{`We do not disclose your personal information to third parties except as required by law or with your explicit consent. However, we may disclose aggregated and anonymized information for statistical or analytical purposes.`}</Text>
            <Text style={styles.title}>{`Data Security:`}</Text>
            <Text style={styles.description}>{`We implement reasonable security measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.`}</Text>
            <Text style={styles.title}>{`Data Retention:`}</Text>
            <Text style={styles.description}>{`We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. `}</Text>
            <Text style={styles.title}>{`Third-Party Links:`}</Text>
            <Text style={styles.description}>{`Our website(s) and app(s) may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party sites you visit.`}</Text>
            <Text style={styles.title}>{`Children's Privacy:`}</Text>
            <Text style={styles.description}>{`Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to remove such information.`}</Text>
            <Text style={styles.title}>{`Changes to this Privacy Policy:`}</Text>
            <Text style={styles.description}>{`We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this Privacy Policy periodically for any updates.`}</Text>
            <Text style={styles.title}>{`Contact Us:`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at contact@CareerBoostConsult.com.`}</Text>
        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Electrolize-Regular",
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Electrolize-Regular",
        },
    }
)

export default PrivacyPage;
