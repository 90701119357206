import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Terms of Use`}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 20, fontWeight: "600", textAlign: "center" }]}>{`Effective as of July 17, 2023`}</Text>

            <Text style={styles.description}>{`Welcome to CareerBoostConsult.com (referred to herein as "we," "us," or "our"). Before utilizing any of our services, please carefully review the following terms and conditions. By accessing or using our website(s) and app(s), you agree to be bound by these Terms of Use. These Terms of Use, along with our Privacy Policy found in the footer of each webpage, constitute a legal agreement between you and CareerBoostConsult.com, applicable to both visitors and members.`}</Text>
            <Text style={styles.title}>{`1. Eligibility and Registration:`}</Text>
            <Text style={styles.description}>{`To access certain features, registration is required. You must provide accurate and complete information and promptly update it as necessary. By registering, you authorize us to verify your account information. You agree not to use false identities or unauthorized credentials.`}</Text>
            <Text style={styles.title}>{`2. License:`}</Text>
            <Text style={styles.description}>{`We grant you a limited, non-exclusive license to use our services for personal purposes, subject to compliance with these Terms of Use. Any commercial use is prohibited.`}</Text>
            <Text style={styles.title}>{`3. Intellectual Property:`}</Text>
            <Text style={styles.description}>{`All content and intellectual property rights associated with our services are owned by CareerBoostConsult.com or our licensors. You agree not to exploit or distribute such content for any purpose other than personal use.`}</Text>
            <Text style={styles.title}>{`4. Use of Services:`}</Text>
            <Text style={styles.description}>{`You agree to use our services only for their intended purposes and not to engage in any activities that could harm or interfere with them. Prohibited activities include the transmission of malicious software or engaging in illegal behaviors.`}</Text>
            <Text style={styles.title}>{`5. Termination or Suspension of Services & Modification of Terms:`}</Text>
            <Text style={styles.description}>{`We reserve the right to modify or terminate our services or these Terms of Use at any time without prior notice. Your continued use of the services constitutes acceptance of any modifications.`}</Text>
            <Text style={styles.title}>{`6. Indemnification:`}</Text>
            <Text style={styles.description}>{`You agree to indemnify and hold CareerBoostConsult.com and its affiliates harmless from any claims arising out of your use of our services or violation of these Terms of Use.`}</Text>
            <Text style={styles.title}>{`7. Disclaimer of Warranty:`}</Text>
            <Text style={styles.description}>{`Our services are provided "as is" without any warranty, express or implied. We do not guarantee uninterrupted or error-free service.`}</Text>
            <Text style={styles.title}>{`8. Limitation of Liability:`}</Text>
            <Text style={styles.description}>{`Under no circumstances will CareerBoostConsult.com be liable for any indirect, incidental, or consequential damages arising from your use of our services.`}</Text>
            <Text style={styles.title}>{`9. Governing Law and Forum:`}</Text>
            <Text style={styles.description}>{`These Terms of Use are governed by the laws of the State of Florida, and any disputes will be resolved in Palm Beach County, Florida.`}</Text>
            <Text style={styles.title}>{`10. Binding Arbitration:`}</Text>
            <Text style={styles.description}>{`Any disputes that cannot be resolved informally will be resolved through binding arbitration, except for disputes regarding intellectual property rights.`}</Text>
            <Text style={styles.title}>{`11. Force Majeure:`}</Text>
            <Text style={styles.description}>{`CareerBoostConsult.com will not be liable for any failure or delay in performance due to events beyond our control.`}</Text>
            <Text style={styles.title}>{`12. Relationship of the Parties:`}</Text>
            <Text style={styles.description}>{`These Terms of Use do not create any partnership, joint venture, or agency relationship between you and CareerBoostConsult.com.`}</Text>
            <Text style={styles.title}>{`13. Notice; Consent to Electronic Notice:`}</Text>
            <Text style={styles.description}>{`You consent to receive notices electronically, and any notice will be deemed received upon transmission.`}</Text>
            <Text style={styles.title}>{`14. Third-Party Links:`}</Text>
            <Text style={styles.description}>{`Our services may contain links to third-party websites, but we are not responsible for the content or activities of these sites.`}</Text>
            <Text style={styles.title}>{`15. App Distributors:`}</Text>
            <Text style={styles.description}>{`Certain parts of our services may be accessed through third-party app distributors, and you agree to review their terms of service.`}</Text>
            <Text style={styles.title}>{`16. Miscellaneous:`}</Text>
            <Text style={styles.description}>{`If any provision of these Terms of Use is found to be invalid, the remaining provisions will remain in effect. These Terms of Use constitute the entire agreement between you and CareerBoostConsult.com.`}</Text>
            <Text style={styles.title}>{`17. No Unsolicited Submissions:`}</Text>
            <Text style={styles.description}>{`We do not accept unsolicited materials, and any such submissions will not be considered.`}</Text>
            <Text style={styles.title}>{`18. Intellectual Property Infringement:`}</Text>
            <Text style={styles.description}>{`If you believe your intellectual property rights have been violated, please contact us.`}</Text>
            <Text style={styles.title}>{`19. Questions:`}</Text>
            <Text style={styles.description}>{`If you have any questions about these Terms of Use, please contact us at contact@CareerBoostConsult.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Electrolize-Regular",
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Electrolize-Regular",
        },
    }
)
export default TermsPage;
